$(() => {
  if ($('.js-hero-prefecture-toggle').length === 0) {
    return;
  }

  $('.js-hero-prefecture-toggle').on('click', e => {
    const $toggle = $(e.currentTarget);
    $toggle.closest('.js-hero-prefectures-wrapper').fadeOut({
      duration: 200,
      complete: () => {
        $($toggle.attr('href')).fadeIn(100);
      },
    });

    return false;
  });
});
