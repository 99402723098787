import SearchConditionForm from '../../lib/search_condition_form';

$(() => {
  const searchConditionForm = new SearchConditionForm();
  if (!searchConditionForm.$form.exists()) return;

  // 親カテゴリにチェックがある際は子カテゴリを検索パラメータに含まないためcheckが外れてしまう。
  // 再描画前に子カテゴリのCheckをつける処理が必要なためここで処理をする
  searchConditionForm.changeChildCheckStateByAllCheckedParents();

  // 駅取得処理
  searchConditionForm.$form.find('.js-search-condition-line').on('click', e => {
    const $line = $(e.currentTarget);
    if ($($line.data('target')).data('rendered')) {
      return;
    }

    $.ajax({
      url: $line.data('url'),
      data: searchConditionForm.$form.serializeArray(),
      dataType: 'script',
    });
  });

  // バブリングさせない
  searchConditionForm.$form.find('.js-clear, .js-line-checkbox').on('click', e => e.stopPropagation());

  // 選択条件を全てクリア
  searchConditionForm.$form.find('.js-clear-form').on('click', () => {
    // valueの値をクリア
    searchConditionForm.$form.find('.js-clear-form-value').text('');
    // formの値をクリア
    searchConditionForm.$form.find('textarea, input[type=text], select').val('');
    searchConditionForm.$form.find(':checked').prop('checked', false);
    // カウントアップする
    searchConditionForm.countUp();
  });
});
