$(() => {
  const $main = $('.js-main');
  const windowHeight = $(window).outerHeight();
  const headerHeight = $('.l_header').outerHeight();
  const footerHeight = $('.l_footer').outerHeight();
  const mainHeight = $main.outerHeight();
  const contentHeight = headerHeight + mainHeight + footerHeight;

  function setMainHeight() {
    const newMainHeight = windowHeight - (headerHeight + footerHeight);
    $main.css({
      height: `${newMainHeight}px`,
      'background-color': '#f6f7fb',
    });
  }

  if (contentHeight < windowHeight) {
    setMainHeight();
  }
});
