import initializeShopMap from '../../lib/shop_map';

$(() => {
  if ($('.js-shop-map').length > 0) {
    // 表示領域から地図の高さを算出
    const height = window.innerHeight - $('header.l_header').height() - $('.js-map-header').height();
    $('.js-shop-map').width('100%').height(height);

    initializeShopMap($('.js-shop-map'));
  }
});
