// 初回スクロール後、1秒以上停止したら表示する
$(() => {
  if ($('.js-button-fixed-on-stop').length > 0) {
    const $fixedButton = $('.js-button-fixed-on-stop');
    let timeout;

    $(window).scroll(() => {
      $fixedButton.removeClass('active');
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        $fixedButton.addClass('active');
      }, 1000);
    });
  }
});
