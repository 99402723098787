import scrollControl from '../../../lib/scroll_control';

$(() => {
  if ($('.js-slide-menu').length === 0) {
    return;
  }

  // メニューの表示処理
  function menuShow($el) {
    $el.show(0, () => $el.css('transform', 'translateX(0)'));
  }

  // メニューの非表示処理
  function menuHide($el) {
    $el.css('transform', '');
    $el.on('transitionend', () => {
      $el.hide();
      $el.off('transitionend');
    });
  }

  $('.js-slide-menu-toggle, .js-gray-panel').on('click', () => {
    const $target = $('.js-slide-menu');
    const $background = $('.js-gray-panel');

    if ($target.is(':visible')) {
      $background.fadeOut(200);
      menuHide($target);
      scrollControl(true);
    } else {
      $background.fadeIn(200);
      menuShow($target);
      scrollControl(false);
    }
  });
});
