// 指定された範囲内で表示する
$(() => {
  if ($('.js-button-fixed').length > 0) {
    const $fixedButton = $('.js-button-fixed');
    const displayPoint = $('.js-button-fixed-display').offset().top;
    const hidePoint = $('.js-button-fixed-hide').offset().top;

    $(window).scroll(() => {
      if ($(window).scrollTop() > displayPoint && $(window).scrollTop() < hidePoint) {
        $fixedButton.addClass('active');
      } else {
        $fixedButton.removeClass('active');
      }
    });
  }
});
