$(() => {
  if (!$('[data-tooltip]').exists()) {
    return;
  }

  const $trigger = $('[data-tooltip]');
  $trigger.on('click', () => {
    $($trigger.data('tooltip')).toggle();
  });
});
