$(() => {
  const $photoSlides = $('.js-photo-slides');
  const $photoSlide = $('.js-photo-slide');

  if (!$photoSlides.exists()) {
    return;
  }

  // スライドの最初と最後では、Arrowは片方しか表示しない
  $photoSlides.on('init reInit afterChange', (event, slick, currentSlide) => {
    const $prevArrow = $photoSlides.find('.prev');
    const $nextArrow = $photoSlides.find('.next');
    const currentSlideNumber = (currentSlide || 0) + 1;

    if (currentSlideNumber === 1) {
      $prevArrow.fadeOut();
      $nextArrow.fadeIn();
    } else if (currentSlideNumber === $photoSlide.length) {
      $prevArrow.fadeIn();
      $nextArrow.fadeOut();
    } else {
      $prevArrow.fadeIn();
      $nextArrow.fadeIn();
    }
  });

  $photoSlides.slick({
    speed: 100,
    infinite: false,
    arrows: true,
    centerMode: true,
    centerPadding: '0',
    prevArrow: '<span class="prev icn icn_cp icf_arrow_left"></span>',
    nextArrow: '<span class="next icn icn_cp icf_arrow_right"></span>',
  });

  // slickが表示される際のチラつきをおさえるために、
  // CSSでは $photoSlides に height を指定し、opacity を0にしている
  $photoSlides.css({
    height: 'auto',
    opacity: 1,
  });
});
