import scrollControl from '../../../lib/scroll_control';

$(() => {
  if (!$('.js-search-condition-modal').exists()) {
    return;
  }

  // モーダルの表示処理
  function modalShow($el) {
    $el.show();
    scrollControl(false);
  }

  // モーダルの非表示処理
  function modalHide($el) {
    $el.hide();
    // 2重でモーダルが上がることがあるので、
    // モーダルがすべて消えてる場合のみスクロールを許容する
    if ($('.js-search-condition-modal:visible').length === 0) {
      scrollControl(true);
    }
  }

  const windowHeight = window.innerHeight;

  $('.js-search-condition-modal-toggle').on('click', e => {
    const $toggle = $(e.currentTarget);
    const $target = $($toggle.data('target'));
    const $except = $($toggle.data('except'));

    // overflow: auto をするために高さの定義が必要なので、
    // 最初にモーダルを開いたときのサイズでスクロールエリアの高さを固定する
    $target.css({
      height: `${windowHeight}px`,
    });
    $target.find('.content').css({
      height: `${$target.height() - 48}px`,
    });

    // 同時に選択すべきじゃない検索条件（駅と都市とか）をリセットする
    if ($except.exists()) {
      $except.find('input[type="checkbox"]').prop('checked', false);
    }

    if ($target.is(':visible')) {
      modalHide($target);
    } else {
      modalShow($target);
    }
  });
});
