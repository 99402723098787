$(() => {
  const $form = $('.js-incremental-search-form');
  if (!$form.exists()) return;

  const $input = $form.find('input');

  $input.keypress(e => {
    if (e.keyCode === 13) return false;
    return true;
  });

  $input.on('input compositionend', e => {
    const keyword = e.currentTarget.value.trim();
    const $currentForm = $(e.currentTarget).closest('.js-incremental-search-form');
    const $container = $currentForm.find('.js-searched-list-container');
    const $list = $container.find('.js-searched-list');
    $list.children().remove();

    if (keyword.length === 0) {
      $container.hide();
      return;
    }

    const url = $currentForm.data('url');
    const q = $currentForm.data('q');
    $.ajax({ url, dataType: 'json', data: { q, keyword } }).done(res => {
      $list.children().remove();

      if (res.length === 0) {
        $container.hide();
        return;
      }

      $container.show();
      res.forEach(data => {
        const $listItem = $(`<li><a href=${data.path}>${data.name}</a></li>`);
        $list.append($listItem);
      });
    });
  });
});
